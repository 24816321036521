import './landingpage.css'
import { Container, Image } from '@mantine/core'
import podcastimage from '../../resources/podcast.png'
import yetulogo from '../../resources/yetulogo.png'
import { useViewportSize } from '@mantine/hooks';

const color1 = "#DDDDD1"

const color2 = "#96864C"
//const color2 = "#F3ECB7"

export function Landingpage() {
    const { height, width } = useViewportSize();
    function Wave(colorclass: string) {
        return (
            <div className="wave">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className={colorclass}></path>
                </svg>
            </div>
        )
    }

    return (
        <div >

            <div className="textdiv" style={{ paddingTop: "5vh", backgroundColor: color1 }}>
                <Container >
                    <h1 className='text-headline'>Yetu Global</h1>

                    <div className="centerBox">
                        <div style={{ maxWidth: "450px" }}>
                            <Image src={yetulogo} />
                        </div>
                    </div>
                    <p className='text'>YETU stands for Youth Empowerment Through Understanding. The goal is to own our narrative by highlighting and sharing stories of Youth. Yetu links youth in agriculture, the environmental sciences, academia, policy makers and practitioners to help build resilient futures.</p>





                </Container>
            </div>
            <div className="wavediv" style={{ backgroundColor: color1 }}>
                {Wave("shape-fill-black")}
            </div>

            <div id="aboutus" className="textdiv" style={{ backgroundColor: color2, color: "black" }}>
                <Container >
                    <div className="centerBox">
                        <div className="centerBox">
                            <div style={{ maxWidth: "450px" }}>
                                <Image src={podcastimage} />
                            </div>
                        </div>                    </div>
                    <p className='text'>Welcome to OUR podcast, the YETU podcast. Where experts and youth come together to build resilient futures with Emily Ongus and Cynthia Amas.</p>

                    <div className="centerBox">
                        <div style={{ padding: width > 600 ? "20px" : "0px", maxWidth: "400px", borderRadius: "5px" }} className="podcastframe">
                            <div className="centerBox"><h1>Episodes</h1></div>
                            <iframe src="https://podcasters.spotify.com/pod/show/yetuglobal/embed/episodes/Episode-1-Youth-in-Digital-Agriculture---The-African-Perspective-e1pep1g" height="102px" style={{ width: width > 600 ? "400px" : "350px" }} frameBorder="0" scrolling="no"></iframe>
                            <iframe src="https://podcasters.spotify.com/pod/show/yetuglobal/embed/episodes/Episode-2-Highlighting-Women-in-Agriculture-on-the-African-Continent-e24e4qd" height="102px" style={{ width: width > 600 ? "400px" : "350px" }} frameBorder="0" scrolling="no"></iframe>

                        </div>
                    </div>


                </Container>
            </div>
            <div className="wavediv" style={{ backgroundColor: color2 }}>
                {Wave("shape-fill-yellow")}
            </div>

            <div className="textdiv" style={{ backgroundColor: color1, padding: 0 }}>
                <div style={{ height: "100px" }}></div>

            </div>

        </div>
    )
}