import './about.css'
import { Container, Image, Grid } from '@mantine/core'
import podcastimage from '../../resources/podcast.png'
import elizabth from '../../resources/ElizabethStyled.png'
import { useViewportSize } from '@mantine/hooks';

const color1 = "#DDDDD1"
const color2 = "#F3ECB7"

export function About() {
    const { height, width } = useViewportSize();
    function Wave(colorclass: string) {
        return (
            <div className="wave">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className={colorclass}></path>
                </svg>
            </div>
        )
    }

    return (
        <div >

            <div className="textdiv" style={{ paddingTop: "5vh", backgroundColor: color1 }}>
                <Container >
                    <Grid>
                        <Grid.Col span={8}>
                            <h1 className='text-headline'>Meet Elizabeth Gulugulu

                            </h1>


                            <p className='text'>
                                Our first encounter with Elizabeth was in 2020 for a workshop organized by YETU and other NGOs on World Food Day. She taught us about the opportunities digitalisation in the agricultural sector bears in resolving recurrent issues, such as drought, water scarcity and floods. Being representative of youth, she advocated for youth activism as an important tool to ensure that governments listen to the demands of youth and to enable a favorable climate for youth-owned agri-businesses. This week, in view of International Women’s Day, we talked to her again.

                                <br /><br />
                                <b>What motivates you about your work?</b>
                                <br />
                                The energy that young people are bringing in to various processes really motivates me, they know what they want.Though they lack adequate support, they don't give up. This consistency and dedication really motivates me.
                                <br /><br /><br />
                                <b>What would you say are key elements to being successful as a woman ?</b>
                                <br />
                                Be bold, unapologetic, factual, engaging and being humble.Tthese are my top elements, though the least goes on!
                                <br /><br /><br />
                                <b>What do you wish to change in your working environment for the next generations of women?</b>
                                <br />
                                I wish to change the state of food insecurity in our communities. We cannot afford to have the next generations advocating for food security [as well], we would have failed. I believe we all have a role to play [and] those on higher power can improve on transparency and accountability. The communities can come up with various initiatives to improve the current state together with other partners like Local councils, NGOs and religious institutions.
                                <br /><br /><br /><br />
                                <b>Why are (young) women so crucial for the agricultural sector?</b>
                                <br />
                                I believe whatever a woman invests into multiplies, it's the nature of a woman and how we were created. Imagine if we plant something, the harvest could be plenty. Just an intervention from a bold young woman can influence good agriculture policies and strategies. We can never ever overlook the role of women in this sector: They are patient and eager to learn.
                                <br /><br /><br /><br />
                                Elizabeth herself is a prime example of a strong, successful woman. She has served as the Program Coordinator of the African Youth Initiative on Climate Change AIYCC in Zimbabwe and is the 2022 Global Focal Point for the UNFCC youth constituency YOUNG. Elizabeth is originally from Zimbabwe and was named one of the top 100 Young African Conservation Leaders. Her vision and passion for climate action are inspiring.

                            </p>

                        </Grid.Col>
                        <Grid.Col span={4}>
                            <div style={{ maxWidth: "450px" }}>
                                <Image src={elizabth} />
                            </div>
                        </Grid.Col>
                    </Grid>





                </Container>
            </div>
            <div className="wavediv" style={{ backgroundColor: color1 }}>
                {Wave("shape-fill-black-latest")}
            </div>

            <div id="aboutus" className="textdiv" style={{ backgroundColor: color2, color: "black" }}>
                <Container >

                    <p className='text'></p>



                </Container>
            </div>
            {/*<div className="wavediv" style={{ backgroundColor: color2 }}>
    {Wave("shape-fill-yellow")}
</div>

<div className="textdiv" style={{ backgroundColor: color1, padding: 0 }}>
    <div style={{ height: "100px" }}></div>

    </div>*/}

        </div>
    )
}