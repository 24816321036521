import { Container, Grid, Image } from '@mantine/core'
import './creators.css'
import cynthiastyled from '../../resources/cynthiastyled.png'
import cynthiastyledblackwhite from '../../resources/cynthiastyledblackwhite.png'
import emilystyled from '../../resources/emilystyled.png'
//#D2BA6A

export function Creators() {
  function Wave(colorclass: string) {
    return (
      <div className="wave">
        <svg viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className={colorclass}></path>
        </svg>
      </div>
    )
  }

  return (
    <div id="creatorspage" >
      <div style={{ backgroundColor: "#D2BA6A" }}>
        <Container >
          <Grid>
            <Grid.Col xs={8}>
              <h1 className='text-headline'>Cynthia Amas</h1>
              <p className='text'>Cynthia Amas strives for global development based on local knowledge and the exchange of knowledge at eye level. Having a multicultural background, she grew up with a heightened awareness of regional development inequalities. With her background in international development studies and governance, Cynthia focuses on policy-making and decision-making processes. With YETU, Cynthia aspires to shift the narrative of such processes to involve the youth at the core of each policy.  WIth her commitment to resolving complex problems and delivering innovative improvement strategies, Cynthia is well versed in analytical research and the utilization and understanding of methods and strategies, such as the Theory of Change. With YETU and by bringing theory into practice, Cynthia wants to pave the way for youth to shape and design their future. YETU stands for empowerment and is a platform that accelerates youth voices and places their narratives, perspectives and ideas at the heart of development processes. </p>
            </Grid.Col>
            <Grid.Col xs={4}>
              <div>
                <div style={{ maxWidth: "300px" }}>
                  <Image src={cynthiastyled} />
                </div>
              </div>
            </Grid.Col>
          </Grid>
        </Container>
        <div className='wavediv'>
          {Wave("shape-fill-creatorpage")}
        </div>
      </div>
      <div style={{ backgroundColor: "#96864C" }}>
        <Container>
          <Grid>
            <Grid.Col xs={4}>
              <div>
                <div style={{ maxWidth: "300px" }}>
                  <Image src={emilystyled} />
                </div>
              </div>
            </Grid.Col>
            <Grid.Col xs={8}>
              <h1 className='text-headline'>Emily Ongus</h1>
              <p className='text'>Emily Ongus believes that learning is a continuous process and co-creation, and collaborations are fundamentals of development. Growing up in Nairobi, Kenya she didn’t envision that she would follow the career path that she has now. Nonetheless with her background in agronomy and a masters in land management she attests to the evolution of her passions. Emily appreciates that her inspiration has been drawn from different places from working with small-holder farmers and visiting orchards in Valencia. She appreciates the human inherent pride and joy that comes from practice. The complexities of working with different communities and the ever-grappling reality of the dwindling environment drive her passion for sustainable development further. Emily has intentionally immersed herself into spaces that allow her to push boundaries and bring representation. She is excited to be part of YETU where she will be able to connect various people to exchange knowledge and discuss topics that are assumed to be in silos. YETU is a “bring-it- home” platform and it doesn’t matter whether you have the skills or understanding. Knowledge is power and YETU intends to allow for the ownership of narratives.</p>
            </Grid.Col>

          </Grid>
          <div style={{ height: "200px" }} />

        </Container>
      </div>
    </div>

  )
}

