import { createStyles, Container, Group, ActionIcon } from '@mantine/core';
import { IconBrandLinkedin, IconBrandTwitter, IconBrandYoutube, IconBrandInstagram } from '@tabler/icons';


const useStyles = createStyles((theme) => ({
  footer: {
    position: "relative",
    marginTop: "-100px",
    borderTop: `0px solid black`,
  },

  inner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,

    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column',
    },
  },

  links: {
    [theme.fn.smallerThan('xs')]: {
      marginTop: theme.spacing.md,
    },
  },
}));

export function FooterSocial() {
  const { classes } = useStyles();

  return (
    <div className={classes.footer}>
      <Container className={classes.inner}>

        <Group spacing={0} className={classes.links} position="right" noWrap>
          <ActionIcon color="dark" size="lg">
            <IconBrandTwitter onClick={() => window.open("https://twitter.com/yetuglobal", '_blank')} size={25} stroke={1.5} />
          </ActionIcon>
          <ActionIcon color="dark" size="lg">
            <IconBrandLinkedin onClick={() => window.open("https://www.linkedin.com/in/yetu-global-5a2a78253", '_blank')} size={25} stroke={1.5} />
          </ActionIcon>
          <ActionIcon color="dark" size="lg">
            <IconBrandInstagram onClick={() => window.open("https://instagram.com/yetuglobal?igshid=YmMyMTA2M2Y=", '_blank')} size={25} stroke={1.5} />
          </ActionIcon>
        </Group>
      </Container>
    </div>
  );
}