import { HeaderResponsive } from '../header'
import { useState } from 'react'
import {
    Routes,
    Route,
} from "react-router-dom";
import './wrapper.css'
import { Landingpage } from '../landingpage';
import { About as Latest } from '../about';
import { Creators as Founders } from '../creators';
import { FooterSocial } from '../footer';

export function Wrapper() {
    const routes = [
        {
            "link": "/",
            "label": "Home"
        },

        {
            "link": "/latest",
            "label": "Latest"
        },

        {
            "link": "/founders",
            "label": "Founders"
        }
    ]
    const [active, setActive] = useState(routes[0].link)

    return (
        <>
            <HeaderResponsive links={routes} changeOpenState={undefined} active={active} setActive={setActive} />
            <Routes>
                <Route path="/" element={<Landingpage />}></Route>
                <Route path="/latest" element={<Latest />}></Route>
                <Route path="/founders" element={<Founders />}></Route>
            </Routes>
            <FooterSocial />
        </>
    )
}